import React from "react";
import './components.css';
import Introduction from './intro.js';


function ShowIntro() {

    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => showResults ? setShowResults(false) : setShowResults(true)

  return (
    <div>
      <button onClick={onClick} className="introButton">{showResults ? "Hide Introduction" : "Show Introduction"}</button>
      { showResults ? <Introduction /> : null }
    </div>
  );
}

export default ShowIntro;
