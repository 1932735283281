import React from "react";
import './components.css';

function Disclaimer() {
    const discInlCss = {
        color: "red",
    }

  return (
    <div className="disclaimer">
        <p><span style={discInlCss} ><b>Disclaimer: </b></span> 
        <span style={{color: "green"}}>I am not a financial advisor. This post's purpose is to provide information and nothing else. If you somehow face any financial losses by investing in any of the services I mentioned in my post, by reading this post you agree to that <b>I cannot be held responsible. Remember: Not your keys, not your coins!</b></span></p>
    </div>
  );
}

export default Disclaimer;
