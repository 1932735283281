import React from "react";
import './components.css';

function Introduction() {

  return (
    <div className="introD">

<h3><u>Glossary:</u></h3>

<p><b>Fixed</b>: Means you always get the same percentage for whatever amount you invest.</p>
<p><b>Variable</b>: Means you are partnering with the casino's bankroll. You might suffer some losses for a while if a whale wins big.</p>

<h3><u>Ratings:</u></h3>

<p><b><span style={{color:"green"}}>Very High</span></b>: Best reputation. Your chances of getting scammed are very low. Probably in business for 10 years which deserves an award of its own.</p>
<p><span style={{color:"green"}}>High</span>: You will likely not get scammed but there are few quirks which irk me.</p>
<p><span style={{color:"orange"}}>Neutral</span>: There are mixed reports or KYC is a problem or not as old as the others.</p>
<p><span style={{color:"red"}}>Low</span>: Bad reputation. High risk.</p>
<p><b><span style={{color:"red"}}>Very Low</span></b>: Stay away.</p>

<hr/>

<p>If there are multiple unsolved&legit scam accusations against any of the casinos I listed, I will first reduce the respective casino's (or the exchange's) rating by 1 or 2 steps. If the accusations resolve in the accuser's favor, I will delete the casino from my list.</p>

<p>I also visited every casino I mentioned in my list and I've seen them still offering investment options so I naturally think they are still in business. If you have a proof showing otherwise, let me know.</p>

<p>These are the opportunities I could find lately. If you know any others, let me know so I can update this topic.</p>

    </div>
  );
}

export default Introduction;
