import React from "react";
import './components.css';
import Outroduction from './outro.js';


function ShowOutro() {

    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => showResults ? setShowResults(false) : setShowResults(true)

  return (
    <div>
      <button onClick={onClick} className="introButton">{showResults ? "Hide Outro" : "Show Outro"}</button>
      { showResults ? <Outroduction /> : null }
    </div>
  );
}

export default ShowOutro;
