import React from "react";
import './components.css';
import Changelog from './changelog.js';


function ShowChangeLog() {

    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => showResults ? setShowResults(false) : setShowResults(true)

  return (
    <div>
      <button onClick={onClick} className="introButton">{showResults ? "Hide Changelog" : "Show Changelog"}</button>
      { showResults ? <Changelog /> : null }
    </div>
  );
}

export default ShowChangeLog;
