import React from "react";
import './components.css';
import parse from 'html-react-parser';



function TableContent() {

    

    const tableCont = [
    {
        serviceName: "Freebitcoin",
        serviceUrl: "https://freebitco.in/",
        serviceImg: "https://www.talkimg.com/images/2024/04/11/juePc.png",
        typeReturn: "Fixed",
        typeAsset: "Btc",
        annualReturn: "4%",
        frequency: "Daily",
        businessAge: "10+ Years",
        myRating: "Neutral",
        ratingNo: "3",
        additionalNotes: ["Min. amount required is 30k sats"]    
    }, 
    {        
        serviceName: "Blackjack.Fun",
        serviceUrl: "https://blackjack.fun/bonus/9f979276-02f1-48df-b928-3daa43781e69",
        serviceImg: "https://www.talkimg.com/images/2024/04/12/jCQfC.png",
        typeReturn: "Fixed",
        typeAsset: "Btc, Ltc and every other coin available",
        annualReturn: "15%",
        frequency: "Hourly",
        businessAge: "5+ Years",
        myRating: "High",
        ratingNo: "4",
        additionalNotes: ["The offer is limited to 20btc site-wide. No min. inv. amount requirement"]    
    },
    {        
        serviceName: "Jambler",
        serviceUrl: "https://jambler.io/",
        serviceImg: "https://www.talkimg.com/images/2024/04/12/jZEc5.png",
        typeReturn: "Variable",
        typeAsset: "Btc",
        annualReturn: "Varies",
        frequency: "~12-24 Hours",
        businessAge: "5+ Years",
        myRating: "Very High",
        ratingNo: "5",
        additionalNotes: ['"The platform offers the possibility of a short-term investment at 1% with an average turnaround time of 12 hours."', '<a href="https://jambler.io/become-seller.php"><sup>[1]</sup></a>', ' Can potentially pay 700% annually as what you get depends on the amount of business they have. Min. investment amount: 0.04Btc']    
    },
    {        
        serviceName: "Binance",
        serviceUrl: "https://accounts.binance.com/register?ref=35350074",
        serviceImg: "https://www.talkimg.com/images/2024/04/11/jwUiD.jpeg",
        typeReturn: "Variable",
        typeAsset: "USDT",
        annualReturn: "Varies",
        frequency: "Daily",
        businessAge: "5+ Years",
        myRating: "Neutral",
        ratingNo: "3",
        additionalNotes: ["Can offer as high as 20% during volatile markets, may ask for docs randomly. No min. inv. amount requirement" ]   
    },
    {        
        serviceName: "Bustabit",
        serviceUrl: "https://bustabit.com",
        serviceImg: "https://www.talkimg.com/images/2024/04/11/jwG8T.png",
        typeReturn: "Variable",
        typeAsset: "Btc",
        annualReturn: "Varies",
        frequency: "-",
        businessAge: "5+ Years",
        myRating: "Neutral",
        ratingNo: "3",
        additionalNotes: ['New investors are charged a fee of 2%, which is shared among existing investors.', '<a href="https://bitcointalk.org/index.php?topic=2897545.msg29795220#msg29795220"><sup>[2]</sup></a>', ' They charge deposits.', '<a href="https://bustabit.com/help/deposit-fee"><sup>[3]</sup></a>']    
    },
    {        
        serviceName: "Bustadice",
        serviceUrl: "https://bustadice.com",
        serviceImg: "https://www.talkimg.com/images/2024/04/11/jwmwl.png",
        typeReturn: "Variable",
        typeAsset: "Btc",
        annualReturn: "Varies",
        frequency: "-",
        businessAge: "5+ Years",
        myRating: "Neutral",
        ratingNo: "3",
        additionalNotes: ['7% commission, "To compensate existing investors for diluting the bankroll, 2% of your investment is deducted before it is added to the bankroll"', '<a href="https://bustadice.com/invest"><sup>[4]</sup></a>', ' Min. investment amount: 0.01Btc.']
    },
    {        
        serviceName: "Bitvest",
        serviceUrl: "https://bitvest.io?r=215077",
        serviceImg: "https://www.talkimg.com/images/2024/04/11/jARSG.png",
        typeReturn: "Variable",
        typeAsset: "Btc, Ltc, Eth, Doge, Bch",
        annualReturn: "Varies",
        frequency: "-",
        businessAge: "~10 Years",
        myRating: "Neutral",
        ratingNo: "3",
        additionalNotes: ["66% of the total profits are shared, sig camp payments are usually delayed which is a bad sign. Min. inv. amount: 0.02Btc, 0.25Ltc, 0.05Eth"]    
    },
    {        
        serviceName: "Just-Dice",
        serviceUrl: "https://just-dice.com",
        serviceImg: "https://www.talkimg.com/images/2024/04/11/jAI0w.gif",
        typeReturn: "Variable",
        typeAsset: "CLAM",
        annualReturn: "Varies",
        frequency: "-",
        businessAge: "10+ Years",
        myRating: "High",
        ratingNo: "4",
        additionalNotes: ["Investment is only available through sea shells, but a very old and reputable casino, 10% comm."]    
    },
    {        
        serviceName: "MintDice",
        serviceUrl: "https://mintdice.com/ref/mrustbtt",
        serviceImg: "https://www.talkimg.com/images/2024/04/11/jAjX1.png",
        typeReturn: "Variable",
        typeAsset: "Btc, Ltc, Eth and a few other",
        annualReturn: "Varies",
        frequency: "-",
        businessAge: "~5 Years",
        myRating: "Neutral",
        ratingNo: "3",
        additionalNotes: ['The creator of ', '<a href="https://bitcointalk.org/index.php?topic=5152050.0">ANN</a>', ' has an inactive btt acc, looks like ', '<a href="https://bitcointalk.org/index.php?action=profile;u=100595">theskillzdatklls</a>', ' is the representative now. The casino seems to be operational. 50% comm. No min. inv. req.', '<a href="https://www.mintdice.com/faq/investing#is-investing-into-the-casino-house-bankroll-a-ponzipyramid-scheme"><sup>[5]</sup><a/>']    
    },]

  return (
    <div className="">

    <div>
        <table className="tableCont">
        <tbody>
            <tr>
                <th style={{width: "3%"}}>No.</th>
                <th style={{width: "10%"}}>Service Name</th>
                <th style={{width: "8%"}}>Type of Return</th>
                <th style={{width: "7%"}}>Asset Type</th>
                <th style={{width: "10%"}}>Pot. Annual Returns</th>
                <th style={{width: "8%"}}>Frequency</th>
                <th style={{width: "10%"}}>Business Age</th>
                <th style={{width: "10%"}}>My Rating</th>
                <th style={{width: "28%"}}>Additional Notes</th>
            </tr>
            {tableCont.map ((service, index) =>{
            return(
                <tr key={index}>
                    <td>{index+1}- </td>
                    <td><a href={service.serviceUrl}><img alt={service.serviceName} className="tableImg" src={service.serviceImg} /></a></td>
                    <td>{service.typeReturn}</td>
                    <td>{service.typeAsset}</td>
                    <td>{service.annualReturn}</td>
                    <td>{service.frequency}</td>
                    <td>{service.businessAge}</td>
                    <td>{service.ratingNo === "5" && <b><span style={{color: "green"}}>{service.myRating}</span></b>}
                    {service.ratingNo === "4" && <span style={{color: "green"}}>{service.myRating}</span>}
                    {service.ratingNo === "3" && <span style={{color: "orange"}}>{service.myRating}</span>}
                    {service.ratingNo === "2" && <span style={{color: "red"}}>{service.myRating}</span>}
                    {service.ratingNo === "1" && <b><span style={{color: "green"}}>{service.myRating}</span></b>}</td>
                    <td className="addNotes">{service.additionalNotes.map( (paragraph, i) => (<span key={i}>{parse(paragraph)}</span>) )}</td>
                </tr>)
            })}
        </tbody>
        </table>
        <br/>
        <br/>
        <br/>


    </div>
    </div>
  );
}

export default TableContent;
