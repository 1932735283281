import React from "react";
import './components.css';

// #5- 04/23/2024: Added casino stats for BustaBit, BustaDice, Bitvest. 
function Changelog() {
  return (
    <div className="changelog">
    <p>
    #1- 04/12/2024: Added Jambler.io to the list with Very High rating <br />
    #2- 04/12/2024: Added minimum investment amount requirement info <br />
    #3- 04/19/2024: Revised Binance's type of return to "variable" as the rate they offer depends on market conditions. <br />
    #4- 04/19/2024: Revised "min. 4% fixed returns" to "min. 4% fixed returns on FIAT" <br />
    #5- 04/23/2024: Launched passivecryptoin.com as theymos banned mixer links in the forum. <br/>
    #6- 05/05/2024: Emergency update. Freebitcoin is experiencing an attack. Don't make any investments till the situation gets resolved. Revised website rating from "very high" to "low" temporarily.<br/>
    #7- 05/19/2024: Freebitco.in's rating is revised from "low" to "neutral". The hacker seems to be gone but no explanation has been given by the maintainer of the website.
    </p>
    </div>
  );
}

export default Changelog;
