import React from "react";
import './components.css';
import ltcicon from '../ltcicon.png';

//<a className="donateltcdiv" title="Litecoin" href="litecoin:ltc1qxsfw3wmml6jgyva6fh7juh5vhl3agxfl78kwfv">Donate:</a> <a title="Litecoin" href="litecoin:ltc1qxsfw3wmml6jgyva6fh7juh5vhl3agxfl78kwfv"><img src={ltcicon} className="footericonltc" alt="donate-litecoin" /></a>


function Footer() {


  return (
    <div className="footer">
  ©2024, <a href="https://passivecryptoin.com">passivecryptoin.com</a>, visit <a href="https://bitcointalk.org/index.php?topic=5492449.0">bitcointalk topic</a>
    </div>
  );
}

export default Footer;
