import React from "react";
import './components.css';

function Introduction() {

    const noteCss = {
        fontSize: "10px",
        textAlign: "center"
    }

  return (
    <div className="introD">

        <p style={noteCss}><span >(This post was originally posted at <a href="https://bitcointalk.org/index.php?topic=5492449.0">bitcointalk</a>. I am <a href="https://bitcointalk.org/index.php?action=profile;u=176777">mindrust</a>, the original author of this post.)</span></p>

<img id="imageIntro" alt="Rockefeller Quote" className="" src="https://www.talkimg.com/images/2024/04/11/jwie9.png" />

        
        <h3>Intro</h3>

<p>I have been reading <a href="https://bitcointalk.org/index.php?topic=5487269">this topic</a> recently and It came to my attention that there isn't a thread which shows passive crypto investment opportunities together so I decided to create one. I've seen <a href="https://bitcointalk.org/index.php?topic=5247587.0">tsaroz's thread</a> but it has't been updated for a long time.</p>

<p>If you don't know what "passive income" means, let me explain it to you first. <b>Passive Income</b> means making your money work for you. It is the only way to make money while you are sleeping. You provide the capital and other people bust their asses off for you. It is the higher level of living. You only manage your investments and collect the income they produce. You may spend them or reinvest them to get higher returns in the future, that's up to you. (Reinvest if you want to get a <a href="https://www.talkimg.com/images/2024/04/11/jupN5.gif">snowballing effect</a>)</p>


<h3>Financial Independence, Retire Early</h3>

<p>The idea of living off of passive income (<a href="https://www.investopedia.com/terms/f/financial-independence-retire-early-fire.asp">FIRE, Financial Independence, Retire Early</a>) is not new and it is very popular especially among the stock investors and they have many communities where they share their ideas and tips. </p>

<p>Here are some of those communities if you are interested:</p>

<ul>
    <li><a href="https://old.reddit.com/r/FIREUK/">r/FIREUK</a></li>
    <li><a href="https://old.reddit.com/r/Fire/">r/Fire</a></li>
    <li><a href="https://old.reddit.com/r/financialindependence/">r/financialindependence</a></li>
</ul>

<p>Obviously these are not the only sources and I am pretty sure there are better and bigger communities out there but I am giving these links just to give you a head start so you can have a basic understanding of what's going on here.
Buying dividend stocks is a great way to build passive income but it is not the only way. The other way is to buy real estate and collecting rent but it is not really passive (but very close) because you have to repair/fix the property and sometimes tenants just don't pay. The other option is collecting interest on your bonds and cash deposits but these are no good in an inflationary environment where the interest rates are always below the inflation and we have always been in an inflationary environment since I don't know when. (since the Great Depression maybe?)
That's why we almost always want to invest in real assets instead of bonds where the underlying asset is FIAT. (unless they offer better rates than the inflation)</p>
<p>Now we've made these things clear let's move on to the real stuff. Otherwise I could write a book on dividends and stocks in this post which nobody here is interested probably. Here I want to share a list of the casinos and the exchanges where you can make crypto (sometimes FIAT) passively. It is impossible to make a list of every opportunity for every altcoin so I'll be focusing mostly on BTC, USDT, and LTC. I am also not going to mention any fixed <b>FIAT</b> returns that's below 4% which is the minimum returns you should be aiming for for any of your cash producing assets. (FIRE rule #1)</p>

<img alt="Satoshi Quote" className="satImg" src="https://www.talkimg.com/images/2024/04/11/jwKGN.png" />

<hr />


    </div>
  );
}

export default Introduction;
