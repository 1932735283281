import React from "react";
import './App.css';
import Disclaimer from './components/disclaimer';
import ShowIntro from './components/showIntro';
import TableContent from './components/tablecontent';
import ShowOutro from './components/showoutro';
import ShowStats from './components/showstats';
import Footer from './components/footer';
import ShowChangeLog from './components/showchangelog';

function App() {


  return (
    <div className="App">
      <Disclaimer />
      <ShowIntro /><br/>
      <TableContent />
      <ShowOutro />
      <ShowChangeLog />

      <Footer />

    </div>
  );
}

export default App;
